import React, { useEffect } from "react"
import {navigate} from "gatsby"
import '../css/custom.css'
import Seo from "../components/seo"
import {GatsbySeo} from "gatsby-plugin-next-seo";

const getRedirectLanguage = () => {
    if (typeof navigator === `undefined`) {
        return "en";
    }

    const lang = navigator && navigator.language && navigator.language.split("-")[0];
    if (!lang) return "en";

    switch (lang) {
        case "fr":
            return "fr";
        default:
            return "en";
    }
};

const IndexPage = ({data}) => {



    useEffect(() => {
        const urlLang = getRedirectLanguage();

        console.log("window.location.pathname="+window.location.pathname)


        if (window.location.pathname === `/`) {
            navigate(`/${urlLang}/`, {replace: true});
        } else {
            //  navigate(`/en/`, {replace: true});
        }

        // navigate(`/${urlLang}/`, {replace: true});
    }, []);

    return(

            <Seo title="Logiciel de gestion de trésorerie"/>
    )
}



export default IndexPage;
